import { Outlet, useLocation } from "react-router-dom";
import { FC, useState, useEffect } from "react";

import styled from "styled-components";
import AppHeader from "./components/app-header/AppHeader";
import HeaderMenu from "./components/app-header/HeaderMenu";
import WorkspacesSideBar from "./components/workspaces-sidebar/WorkspacesSideBar";
import BottomProgressUploading from "components/common/BottomProgressUploading";
import BottomUploadComplete from "components/common/BottomUploadComplete";
import { observer } from "mobx-react";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import StorePermission from "./components/workspaces-sidebar/StorePermission";
import AccessRestricted from "./features/Dashboard/AccessRestricted";
import BannerWrapper from "components/bannerWrapper";
import hotspotsStore from "./features/Dashboard/hotspots/store";
import { downloadFileFromUrl } from "utils/downloadFile";

type AppWrapperProps = {};

const AppWrapper: FC<AppWrapperProps> = observer(() => {
  const [openLeftSidebar, setOpenLeftSidebar] = useState(false);
  const location = useLocation();
  const handleOpenLeftSidebar = (state: boolean) => setOpenLeftSidebar(state);
  const allowedOutlet = ['/', '/select-workspace'];

  const isDashboard = location.pathname.includes('dashboard');

  useEffect(() => {
    const mainScrollContent = document.getElementById('main-scroll-content');
    if (mainScrollContent) {
      mainScrollContent.scrollTop = 0;
    }
  }, [location.pathname]);

  const isDownloadOnQueue = hotspotsStore.isDownloadOnQueue;
  const exportXlsxUrl = hotspotsStore.exportXlsxUrl;
  useEffect(() => {
    if (isDownloadOnQueue && exportXlsxUrl) {
      downloadFileFromUrl(exportXlsxUrl);
      hotspotsStore.setData(false, "isDownloadOnQueue");
    }
  }, [isDownloadOnQueue, exportXlsxUrl]);
  
  return (
    <AppMainWrapper
      $isDashboard={isDashboard}
      id="main-scroll-content"
    >
      <AppHeader handleOpenLeftSidebar={handleOpenLeftSidebar} />
      <HeaderMenu />
      <BannerWrapper />
      <AppContent
        $isImpersonated={StoreLayout.isImpersonated}
        $isDashboard={isDashboard}
      >
        {StorePermission.hasAnyEntityPermission ||
          StorePermission.hasAnyWorkspacePermission ||
          allowedOutlet.includes(location.pathname)
          ? <Outlet />
          : <AccessRestricted />
        }
        <WorkspacesSideBar
          open={openLeftSidebar}
          handleHide={handleOpenLeftSidebar}
        />
      </AppContent>
      <BottomProgressUploading />
      <BottomUploadComplete />
    </AppMainWrapper>
  );
});

const AppMainWrapper = styled.div<{ $isDashboard: boolean }>`
  overflow-y: ${props => props.$isDashboard ? "auto" : "hidden"};
  height: ${props => props.$isDashboard ? "100vh" : "auto"};
  position: relative;
`;

const AppContent = styled.div<{ $isDashboard: boolean, $isImpersonated?: boolean }>`
  width: 100%;
  ${props => !props.$isDashboard && `
    height: ${props.$isImpersonated ? "calc(100vh - 112px)" : "calc(100vh - 72px)"};
  `}
  overflow-y: ${props => !props.$isDashboard ? "auto" : "none"};
`;

export default AppWrapper;
