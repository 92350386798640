import { ReactNode, useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { SelectedItem } from "types/permission";
import { Checkbox } from "@mui/material";
import styled from "styled-components";

type Anchor = {
  vertical: "bottom" | "center" | "top" | number;
  horizontal: "center" | "left" | "right" | number;
};

type DropdownPopOverProps = {
  anchorElement: HTMLElement;
  isOpen: boolean;
  items: SelectedItem[];
  updateSelectedItems?: SelectedItem[] | null;
  resetFilter?: boolean;
  children?: ReactNode | null;
  customStyle?: any;
  anchorPosition?: Anchor;
  onSelect: (selectedItems: SelectedItem[]) => void;
  onClose: (isOpen: boolean) => void;
  onReset?: () => void | null;
};

const DropdownPopOver = ({
  anchorElement,
  isOpen,
  items,
  updateSelectedItems = null,
  resetFilter = false,
  children = null,
  customStyle = "",
  anchorPosition = {
    vertical: "bottom",
    horizontal: "left",
  },
  onSelect,
  onClose,
  onReset = null,
}: DropdownPopOverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(anchorElement);
  const [open, setOpen] = useState(isOpen);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[] | null>(null);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (anchorElement !== anchorEl) {
      setAnchorEl(anchorElement);
    }
  }, [anchorElement]);

  useEffect(() => {
    if (selectedItems !== null) {
      onSelect(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (resetFilter) {
      setSelectedItems(null);
      onReset?.();
    }
  }, [resetFilter]);

  useEffect(() => {
    if (updateSelectedItems && selectedItems && updateSelectedItems.length !== selectedItems.length) {
      setSelectedItems(updateSelectedItems);
    }
  }, [updateSelectedItems]);

  const handleSelectRow = (selectedData: SelectedItem) => {
    setSelectedItems((prevSelected) =>
      prevSelected && prevSelected.includes(selectedData)
        ? prevSelected.filter((item) => item.id !== selectedData.id)
        : [...(prevSelected || []), selectedData]
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);

    onClose(false);
  };

  const id = open ? "filtering-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorPosition}
    >
      <MainContainer style={customStyle ? customStyle : {}}>
        {items?.map((option, i) => {
          return (
            <ItemContentWrapper
              id={`Filter_Content_Wrapper_${i}`}
              key={`Filter_Content_Wrapper_${i}`}
              onClick={(e) => {
                if ((e.target as HTMLElement).tagName === "INPUT") return;
                handleSelectRow(option);
              }}
            >
              <Checkbox
                id={`Select_Option_Checkbox_${i}`}
                size="small"
                checked={selectedItems?.includes(option) ?? false}
                onChange={() => handleSelectRow(option)}
              />
              <LabelName>{option.name}</LabelName>
            </ItemContentWrapper>
          );
        })}

        {children && <>{children}</>}
      </MainContainer>
    </Popover>
  );
};

const ItemContentWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid var(--colorPaletteGrayBackground1);
  cursor: pointer;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelName = styled.div`
  cursor: pointer;
`;

export default DropdownPopOver;
