import http from "api/https";
import { createUrl } from "../../helpers/url";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

export const resendInvitationUsersToRoles = (
  emails: string[],
  roleIds: number[],
  entityId: number,
  regionId: number
): Promise<any> => {
  const data: {
    access_level_code: string,
    emails: string[],
    role_ids: number[],
    entity_id: number,
    region_id: number,
  } = {
    access_level_code: ACCESS_LEVEL_TYPE.entity,
    emails: emails,
    role_ids: roleIds,
    entity_id: entityId,
    region_id: regionId,
  };

  const url = createUrl({
    path: "/permission/entity_permission/ResendInvitation"
  });
  return http.postGO(url, data);
};
