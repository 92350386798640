export const API_URL = window["_env_"]?.REACT_APP_BASE_API ?? process.env.REACT_APP_BASE_API
export const ISAPI_TEMPLATE_GO = true;
export const API_TEMPLATE_URL_GO = window["_env_"]?.REACT_APP_BASE_API_GO ?? process.env.REACT_APP_BASE_API_GO;
export const ENABLE_IN_DEVELOPMENT_FEATURES = getVariable("REACT_APP_ENABLE_IN_DEVELOPMENT_FEATURES") === "true";

export const USE_DASHBOARD_API = getVariable("REACT_APP_USE_DASHBOARD_API") === "true";

// Feature flag for functionality that:
// - Should still continue to work with old dashboard
// - Should be disabled for new dashboard
// - Should be able to be turned on for testing via another flag
export const IS_OLD_DASHBOARD_OR_ENABLED_FEATURE = !USE_DASHBOARD_API || ENABLE_IN_DEVELOPMENT_FEATURES;

function getVariable(variableName: string) {
    const localStorageValue = localStorage.getItem(variableName);
    if (localStorageValue === null) {
        return process.env[variableName];
    }
    return localStorageValue;
}
