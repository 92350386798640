import { makeAutoObservable, runInAction } from "mobx";
import {
  dictionary_language,
  Question,
  question_dimension,
  QuestionnaireTemplate,
  templ_question,
  templ_template_comms,
  typeQuestionary,
} from "types/templates";
import MainStore from "MainStore";
import { getQuestionnaireTemplate } from "api/go/useGetQuestionnaireTemplate";
import { getAllLanguagesForSurvey } from "api/go/useGetAllLanguagesForSurvey";
import { saveQuestionnaireTemplate } from "api/go/useSaveQuestionnaireTemplate";
import { addQuestionnaireTemplate } from "api/go/useAddQuestionnaireTemplate";
import { getTemplateQuestionDimensions } from "api/go/useGetTemplateQuestionDimensions";
import { saveTemplateQuestionDimensions } from "api/go/useSaveTemplateQuestionDimensions";
import { getAllQuestionDimensions } from "api/go/useGetAllQuestionDimensions";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import modalStore from "features/QuestionTemplate/store";
import { getTemplTypeQuestionary } from "api/go/useGetTemplTypeQuestionary";
import { getTemplatesFilter } from "api/go/useGetTemplatesFilter";
import { duplicateQuestionnaireTemplate } from "api/go/useDuplicateQuestionnaireTemplate";
import { attribute_with_segments, newQuestions } from "types/surveys";
import { getAttributesAndSegmentsByEntity } from "api/go/useGetAttributesAndSegmentsByEntity";
import { surveyLocalization } from "survey-core";
import { checkUniqueQuestionaireTemplateName } from "api/go/useCheckUniqueQuestionaireTemplateName";
import { createUrl } from "helpers/url";

class Store {
  idTemplate = 0;

  data: templ_template_comms[] = [];
  unchanged_data: templ_template_comms[] = [];

  openSelectTemplate: boolean = false;
  typesQuestionary: typeQuestionary[] = [];
  checkedId: number;
  QuestionnaireTemplate: QuestionnaireTemplate = null;
  SupportedLanguages: dictionary_language[] = [];
  TemplateQuestionDimensions: templ_question[] = [];
  QuestionDimensions: question_dimension[] = [];
  selectedType: number = 0;
  navUrl = "";
  expandedNav = "";
  groupUrl = "";
  resultSearchValue: string = "";
  sortField: string | null = "";
  sortType: string | null = "";
  Attributes: attribute_with_segments[] = [];
  survey_used_locales: string[] = [];
  questionJsons: Question[] = [];
  openDimensionErrorMessage: boolean = false;
  survey_before: string = "";
  survey_text: string = "";
  survey_title: string = "";
  activePage: "edit" | "translation" | "dimension" | "preview" = "edit";
  questionsForAddBefore: newQuestions[] = [];
  questions_for_add: newQuestions[] = [];
  typeReplaceQuestions: "add" | "replace" = null;
  openSelectTemplateConfirm: boolean = false;
  openModalTags: boolean = false;
  isTitleError: boolean = false;

  changedSurvey: boolean = false;
  changedTranslation: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data: templ_template_comms[]) => {
    this.data = data;
    this.unchanged_data = data;
  };

  changeOpenDimensionErrorMessage = (flag: boolean) => {
    this.openDimensionErrorMessage = flag;
  };

  loadAllData = (id: number) => {
    this.idTemplate = id;
    if (id === 0) {
      return alert(id);
    }
    this.loadMainInformation(id);
  };

  loadMainInformation(id: number) {
    MainStore.changeLoader(true);
    getQuestionnaireTemplate(id).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.QuestionnaireTemplate = x.data;
        this.survey_before = x.data.survey;
        this.survey_text = x.data.survey;
        this.survey_title = x.data.name;
        modalStore.templateId = id;
        this.survey_used_locales = x.data.locales;
        this.questionJsons = x.data.questionJson;
      }
    });
  }

  onClickBtn = (nav: string, group: string) => {
    this.selectedType = null;
    this.navUrl = nav;
    this.groupUrl = group;
    this.checkedId = null;
    this.getTemplatesFilter();
  };

  getAllSupportedLanguages() {
    MainStore.changeLoader(true);
    getAllLanguagesForSurvey().then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.SupportedLanguages = x.data;
        surveyLocalization.supportedLocales = store.SupportedLanguages.map((lang) => lang.iso);
      }
    });
  }

  loadAttributesAndSegmentsByEntity() {
    MainStore.changeLoader(true);
    getAttributesAndSegmentsByEntity(StoreLayout.currentEntityId).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.Attributes = x.data;
      }
    });
  }

  settypeQuestionary = (data: typeQuestionary[]) => {
    var questionnaire: typeQuestionary = {
      id: 0,
      code: "All templates",
      name: "All templates",
      iconColor: "",
      queueNumber: "",
      idCustomSvgIcon: 1,
    };

    this.typesQuestionary.push(questionnaire);

    this.typesQuestionary = data;

    this.typesQuestionary.unshift(questionnaire);
  };

  loadtypeQuestioonary = () => {
    MainStore.changeLoader(true);
    getTemplTypeQuestionary().then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.settypeQuestionary(x.data);
      }
    });
  };

  duplicateTemplate(callbackOnSaved: (id: number) => void) {
    MainStore.changeLoader(true);
    return duplicateQuestionnaireTemplate(this.checkedId, StoreLayout.currentEntityId).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        callbackOnSaved(x.data);
      }
    });
  }

  changeSelectedType = (id: number) => {
    this.selectedType = id;
    console.log("selecded type is:" + this.selectedType);
    this.getTemplatesFilter();
  };

  getTemplateQuestionDimensions() {
    MainStore.changeLoader(true);
    getTemplateQuestionDimensions(this.idTemplate).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.TemplateQuestionDimensions = x.data;
      }
    });
  }

  getAllQuestionDimensions() {
    MainStore.changeLoader(true);
    getAllQuestionDimensions().then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.QuestionDimensions = x.data;
      }
    });
  }

  getTemplatesFilter = () => {
    let nav = this.navUrl === null || this.navUrl === "" ? "engageRocket" : this.navUrl;
    let group = this.groupUrl === null || this.groupUrl === "" ? "all" : this.groupUrl;
    MainStore.changeLoader(true);
    getTemplatesFilter(
      nav,
      group,
      StoreLayout.currentEntityId,
      this.resultSearchValue,
      this.sortField,
      this.sortType,
      this.selectedType
    ).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.setData(x.data);
      }
    });
  };

  changeTab = async (tab: "edit" | "dimension" | "translation" | "preview") => {
    if (this.activePage !== tab && this.idTemplate !== 0) {
      const valid = await this.checkValidTitle();
      if (valid) {
        await this.saveQuestionnaireTemplate(() => {}, valid);
        this.changeActivePage(tab);
      }
    }
  };

  checkValidTitle = async () => {
    if (this.activePage === "edit") {
      if (this.survey_title === "" || this.survey_title === null) {
        this.isTitleError = true;
        MainStore.setSnackbar("Survey title is required", "error");
        return false;
      }
      const isUnique = await this.checkUniqueSurveyName();
      if (!isUnique) {
        this.isTitleError = true;
        MainStore.setSnackbar("The title must be unique", "error");
        return false;
      }
      this.isTitleError = false;
      return true;
    }
    return true;
  };

  checkUniqueSurveyName = async () => {
    try {
      MainStore.changeLoader(true);
      return await checkUniqueQuestionaireTemplateName(
        this.survey_title,
        this.idTemplate,
        StoreLayout.currentEntityId
      );
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
    return false;
  };

  createQuestionnaireTemplate = () => ({
    id: 0,
    description: "",
    name: this.survey_title,
    istemplateER: false,
    survey: this.survey_text || "{}",
    estimation: "",
    entity_id: StoreLayout.currentEntityId,
    owner_entity_id: StoreLayout.currentEntityId,
    locales: this.survey_used_locales,
    questionJson: this.questionJsons,
  });

  handleAddQuestionnaireTemplate = async (navigate: (path: string) => void, just_save: boolean) => {
    this.QuestionnaireTemplate = this.createQuestionnaireTemplate();
    const response = await addQuestionnaireTemplate(this.QuestionnaireTemplate);
    if ((response.status === 200 || response.status === 201) && response?.data) {
      this.survey_before = this.QuestionnaireTemplate.survey;
      store.loadAllData(Number(response.data));
      modalStore.changeTemplateId(Number(response.data));
      if (!just_save) {
        navigate(
          createUrl({ path: "/questionnaire-template", query: { id: response.data.toString() } })
        );
        this.changeActivePage(this.activePage === "edit" ? "translation" : "dimension");
      }
      return;
    }
    if (response.response.status === 422) {
      if (response.response?.data?.error === "question titles must be unique") {
        return MainStore.setSnackbar("Question titles must be unique", "error");
      }
    }
  };

  handleUpdateQuestionnaireTemplate = async (just_save: boolean) => {
    runInAction(() => {
      if (this.survey_text) {
        this.QuestionnaireTemplate.survey = this.survey_text;
      }
      this.QuestionnaireTemplate.name = this.survey_title;
      this.QuestionnaireTemplate.locales = this.survey_used_locales;
      this.QuestionnaireTemplate.questionJson = this.questionJsons;
    });
    const response = await saveQuestionnaireTemplate(this.QuestionnaireTemplate);
    if ((response.status === 200 || response.status === 201) && response?.data) {
      this.survey_before = this.QuestionnaireTemplate.survey;
      if (!just_save) {
        this.changeActivePage(this.activePage === "edit" ? "translation" : "dimension");
      }
      return;
    }
    if (response.response.status === 422) {
      if (response.response?.data?.error === "question titles must be unique") {
        return MainStore.setSnackbar("Question titles must be unique", "error");
      }
    }
  };

  handleSaveTemplateQuestionDimensions = async (
    navigate: (path: string) => void,
    just_save: boolean
  ) => {
    const response = await saveTemplateQuestionDimensions(this.TemplateQuestionDimensions);
    if (response?.data && !just_save) {
      navigate("/templates");
      MainStore.setSnackbar(`"${this.QuestionnaireTemplate.name}" template successfully saved!`);
    }
  };

  saveQuestionnaireTemplate = async (navigate: (path: string) => void, just_save = false) => {
    try {
      if (store.activePage === "dimension" && !just_save && !store.checkDimensionValidation()) {
        this.changeOpenDimensionErrorMessage(true);
        return;
      }
      MainStore.changeLoader(true);
      if (this.activePage === "edit" || this.activePage === "translation") {
        if (this.QuestionnaireTemplate === null) {
          await this.handleAddQuestionnaireTemplate(navigate, just_save);
        } else {
          await this.handleUpdateQuestionnaireTemplate(just_save);
        }
      } else {
        await this.handleSaveTemplateQuestionDimensions(navigate, just_save);
      }
      this.changedSurvey = false;
      this.changedTranslation = false;
      MainStore.changeLoader(false);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  checkDimensionValidation = () => {
    let new_ques_dimen = this.TemplateQuestionDimensions;
    new_ques_dimen.forEach((x) => {
      if (
        x.dimension_idNavName === null ||
        x.dimension_idNavName === undefined ||
        x.dimension_idNavName === ""
      ) {
        x.dimension_is_required = true;
      } else {
        x.dimension_is_required = false;
      }
    });
    this.TemplateQuestionDimensions = new_ques_dimen;
    let canSave = new_ques_dimen.filter((x) => x.dimension_is_required).length === 0;
    return canSave;
  };

  changeSort = (field: string, type: string) => {
    this.sortField = field;
    this.sortType = type;
    this.getTemplatesFilter();
  };

  changeMainSurvey = (survey_text: string) => {
    this.survey_text = survey_text;
  };

  setSurveyChanged = (flag: boolean) => {
    this.changedSurvey = flag;
  };

  setTranslationChanged = (flag: boolean) => {
    this.changedTranslation = flag;
  };

  changeTitleSurvey = (survey_title: string) => {
    this.survey_title = survey_title;
    this.isTitleError = false;
  };

  changeActivePage = (page: "edit" | "dimension" | "translation" | "preview") => {
    if (page === "dimension") {
      this.getTemplateQuestionDimensions();
    }
    this.activePage = page;
    this.changedSurvey = false;
    this.changedTranslation = false;
  };

  changeUsedLocales = (locales: string[]) => {
    this.survey_used_locales = locales;
  };

  changeQuestions = (questions: Question[]) => {
    this.questionJsons = questions;
  };

  changeDimension = (value: string, index: number) => {
    this.TemplateQuestionDimensions[index].dimension_idNavName = value ? value.trim() : null;
  };
  changeCalculateScore = (value: boolean, index: number) => {
    this.TemplateQuestionDimensions[index].isCalculateScore = value;
  };
  changeSelectTemplate = (flag: boolean) => {
    this.openSelectTemplate = flag;
  };
  backToTemplates = () => {
    this.changeOpenSelectTemplateConfirm(false);
    this.changeOpenSelectTemplate(true);
  };
  changeOpenSelectTemplate = (flag: boolean) => {
    this.openSelectTemplate = flag;
  };

  templateLibraryAdded = (questions: newQuestions[]) => {
    this.openSelectTemplate = false;
    if (!!questions.length && !!this.questionJsons.length) {
      this.changeOpenSelectTemplateConfirm(true);
      this.questionsForAddBefore = questions;
    } else {
      this.questionsForAddBefore = questions;
      this.confirmReplaceQuestions("replace");
    }
  };

  changeOpenSelectTemplateConfirm = (flag: boolean) => {
    this.openSelectTemplateConfirm = flag;
  };

  confirmReplaceQuestions = (typeReplaceQuestions: "add" | "replace") => {
    this.changeOpenSelectTemplateConfirm(false);
    this.questions_for_add = this.questionsForAddBefore;
    this.typeReplaceQuestions = typeReplaceQuestions;
  };

  setModalOpen = (val: boolean) => {
    this.openModalTags = val;
  };
}

const store = new Store();
export default store;
