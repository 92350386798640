import { API_URL, API_TEMPLATE_URL_GO } from "constants/config";
import MainStore from "MainStore";
import { cognitoService } from 'CognitoService';
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

const API_TIMEOUT = 5 * 60 * 1000;

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const createAPIClient = (baseURL: string) => {
  const client = axios.create({ baseURL, timeout: API_TIMEOUT });
  client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const accessToken = cognitoService.getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });
  client.interceptors.response.use(
    (response) => response as any,
    async (error: AxiosError) => {
      const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

      if (!originalRequest) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const response = await cognitoService.refreshToken();
            const newToken = response.AuthenticationResult?.AccessToken;

            if (!newToken) {
              throw new Error('No token received from refresh');
            }

            isRefreshing = false;
            refreshSubscribers.forEach(callback => callback(newToken));
            refreshSubscribers = [];

            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return client(originalRequest);
          } catch (refreshError) {
            isRefreshing = false;
            refreshSubscribers = [];
            cognitoService.logout();
            return Promise.reject(refreshError);
          }
        } else {
          // Wait for the token refresh
          return new Promise(resolve => {
            refreshSubscribers.push((token: string) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(client(originalRequest));
            });
          });
        }
      }

      if (error.response?.status === 403) {
        MainStore.setOpenError403(true);
      }

      if (error.response?.status === 422) {
        // @ts-ignore
        MainStore.setSnackbar(error.response.data.Content, "error");
      }

      return Promise.reject(error);
    }
  );
  return client;
}

const httpGO = createAPIClient(API_TEMPLATE_URL_GO);
const http = createAPIClient(API_URL)

const uanthenticatedHttpGo = axios.create({
  baseURL: API_TEMPLATE_URL_GO
});

const isAPIGO = true

const postUnauthenticatedGO = (url: string, data: any, headers = {}, params = {}) => {
  return uanthenticatedHttpGo.post(url, data, {
    ...params,
    headers: {
      ...headers
    }
  });
};


const getGO = (url: string, headers = {}, params = {}) => {
  return httpGO.get(url, {
    ...params,
    headers: {
      ...headers,
    }
  });
};

const postGO = (url: string, data: any, headers = {}, params = {}) => {
  return httpGO.post(url, data, {
    ...params,
    headers: {
      ...headers
    }
  });
};

const removeGO = (url: string, data: any, headers = {}) => {
  return httpGO.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};

const patchGO = (url: string, data: any, headers = {}) => {
  return httpGO.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const putGO = (url: string, data: any, headers = {}) => {
  return httpGO.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};
const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  isAPIGO,
  postUnauthenticatedGO,
  getGO,
  postGO,
  removeGO,
  patchGO,
  putGO,
  http,
  get,
  post,
  put,
  remove,
  patch,
  dashboardAPIClient: createAPIClient(process.env.REACT_APP_DASHBOARD_API || API_TEMPLATE_URL_GO)
};

export default module;
