import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { TextField, Stack } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CustomModal from "components/modal/Modal";
import CustomButton from "components/button/Button";

interface RevokeUserPermissionModalProps {
  id?: string;
  open: boolean;
  emails: string[];
  onClose: () => void;
  submitRevokeHandler: () => void;
}

const RevokeUserPermissionModal = ({
  id = "",
  open,
  emails,
  onClose,
  submitRevokeHandler,
}: RevokeUserPermissionModalProps) => {
  const [isValidConfirmation, setIsValidConfirmation] = useState<boolean>(false);
  const [revokeConfirmation, setRevokeConfirmation] = useState("");
  const [revokeConfirmationError, setRevokeConfirmationError] = useState<string | null>(null);

  const handleOnTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRevokeConfirmation(event.target.value);

    if (event.target.value === event.target.placeholder) {
      setIsValidConfirmation(true);
      setRevokeConfirmationError(null);

      return;
    }

    if (event.target.value === "") {
      setRevokeConfirmationError("Enter “REVOKE” to confirm this action");
    }

    setIsValidConfirmation(false);
  };

  const handleOnClose = () => {
    setRevokeConfirmation("");
    setRevokeConfirmationError(null);
    setIsValidConfirmation(false);

    onClose();
  };

  return (
    <CustomModal id={id} open={open} hide={onClose} width="600px" padding="0">
      <FormWrapper>
        <Header data-testid="EntityPermissions_Revoke_User_popup_title">
          Are you sure you want to revoke access for the following users?
        </Header>
        <AddedEmailBox>
          <EmailsCountLabel>Selected users ({emails.length} users):</EmailsCountLabel>
          {emails?.length > 0 && <EmailLabels>{emails.join(", ")}</EmailLabels>}
        </AddedEmailBox>

        <RevokeStatement>
          To permanently revoke access for the users, please enter REVOKE below:
        </RevokeStatement>

        <RevokeStatementWrapper>
          <TextField
            data-testid="EntityPermissions_Revoke_Users_popup_EmailInput"
            fullWidth
            type="text"
            placeholder="REVOKE"
            variant="standard"
            sx={{
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
              "& .MuiInputLabel-root": {
                transform: "translate(0, 20px) scale(1)",
                transition: "none",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                transform: "translate(0, 20px) scale(1)",
              },
            }}
            error={revokeConfirmationError === ""}
            value={revokeConfirmation}
            onChange={handleOnTextChange}
          />

          <LabelError>{revokeConfirmationError}</LabelError>
        </RevokeStatementWrapper>
      </FormWrapper>

      <StyledHelperText>
        <ReportProblemIcon color="warning" />
        <Typography data-testid="EntityPermissions_AddUsers_popup_warning">
          Upon confirmation, users will be revoked from the entity immediately.
        </Typography>
      </StyledHelperText>
      <BtnWrp>
        <CustomButton
          data-testid="EntityPermissions_AddUsers_popup_AddBtn"
          onClick={() => {
            submitRevokeHandler();
            handleOnClose();
          }}
          variant="contained"
          color="primary"
          disabled={!isValidConfirmation}
        >
          Revoke users
        </CustomButton>
        <CancelButton
          data-testid="EntityPermissions_AddUsers_popup_CanselBtn"
          onClick={handleOnClose}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </CancelButton>
      </BtnWrp>
    </CustomModal>
  );
};

const FormWrapper = styled.div`
  padding: 60px 60px 0px;
`;

const RevokeStatementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const StyledHelperText = styled.div`
  padding: 24px 60px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const BtnWrp = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 60px 48px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 24px;
`;

const Typography = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  white-space: nowrap;
`;

const AddedEmailBox = styled.div`
  height: 150px;
  overflow: auto;
  border: 1px solid var(--colorNeutralBackground3);
  background: var(--colorPaletteGrayBackground1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorNeutralForeground5);
  }
`;

const RevokeStatement = styled.p`
  font-weight: 400px;
  size: 14px;
  line-height: 20px;
  padding-top: 24px;
  color: var(--colorNeutralForeground1);
`;

const EmailsCountLabel = styled.p`
  font-weight: 500px;
  size: 14px;
  line-height: 20px;
  padding-bottom: 4px;
`;

const LabelError = styled.p`
  font-weight: 400px;
  size: 14px;
  line-height: 20px;
  color: var(--colorPaletteRedForeground2);
  padding-top: 4px;
`;

const EmailLabels = styled(Stack)`
  font-weight: 400px;
  size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
`;

export default RevokeUserPermissionModal;
