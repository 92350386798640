import { observer } from "mobx-react";
import { ROLE_LIST } from "constants/role-list";
import { FC, useEffect, useState, useCallback, useRef } from "react";
import { Button, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import dayjs from "dayjs";
import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import GraphView from "./GraphView";
import ErrorIcon from "@mui/icons-material/Error";
import DimensionsList from "./DimensionsList";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE } from "constants/config";
import DownloadIcon from '@mui/icons-material/Download';
import PrioritizeContent from "./PrioritizeContent";
import { PrioritizeElementRenderer } from "./PrioritizeElementRenderer";

type PrioritizeScreenProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
  onPdfDownload: () => void;
};

const PrioritizeScreen: FC<PrioritizeScreenProps> = observer((props) => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = useCallback((el: HTMLElement) => {
    const scrollTop = el.scrollTop;
    const threshold = 244;
    
    requestAnimationFrame(() => {
      setIsSticky(scrollTop > threshold);
    });
  }, []);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "prioritize" &&
      topStore.overviewBenchmark !== undefined
    ) {
      topStore.getSurveyResponseCount(props.localFilters);
    }
  }, [
    topStore.selectedSurvey,
    props.localFilters,
    topStore.activeTab,
    topStore.overviewBenchmark,
    topStore.role,
    topStore.secondaryRole,
  ]);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "prioritize" &&
      topStore.isSurveyHaveResponseCount
    ) {
      store.getQuestionList({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    topStore.selectedSurvey,
    topStore.activeTab,
    props.localFilters,
    topStore.role,
    topStore.secondaryRole,
    topStore.isSurveyHaveResponseCount,
  ]);

  useEffect(() => {
    if (
      topStore.activeTab === "prioritize" &&
      store.selectedQuestion &&
      topStore.isSurveyHaveResponseCount
    ) {
      store.getDimensionList({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        questionId: store.selectedQuestion,
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    topStore.activeTab,
    props.localFilters,
    store.selectedQuestion,
    topStore.role,
    topStore.secondaryRole,
    topStore.isSurveyHaveResponseCount,
  ]);

  useEffect(() => {
    if (
      topStore.activeTab === "prioritize" &&
      store.selectedQuestion &&
      store.selectedPrioritize &&
      store.selectedPrioritize.length > 2 &&
      topStore.isSurveyHaveResponseCount
    ) {
      store.getChartData({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        questionId: store.selectedQuestion,
        dimensions: store.prioritizeList
          .map((el) => {
            if (store.selectedPrioritize.includes(el.id)) {
              return el.name;
            }
          })
          .filter((item) => item),
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    topStore.activeTab,
    props.localFilters,
    store.selectedQuestion,
    store.selectedPrioritize,
    topStore.role,
    topStore.secondaryRole,
    topStore.isSurveyHaveResponseCount,
  ]);

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");
    if (!el) return;

    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          handleScroll(el);
          ticking = false;
        });
        ticking = true;
      }
    };
    
    el.addEventListener("scroll", scrollHandler, { passive: true });

    return () => {
      el.removeEventListener("scroll", scrollHandler);
    };
  }, [handleScroll]);

  const renderer = new PrioritizeElementRenderer({localFilters: props.localFilters});

  return (
    <Container>
      <TopWrapper $isSticky={isSticky}>
        {IS_OLD_DASHBOARD_OR_ENABLED_FEATURE ? (
          <TopContainer $isSticky={isSticky}>
            <TopContainerText>
              Viewing results for {topStore.calculateRespondentsCount} respondents
            </TopContainerText>
            <Divider>|</Divider>
            <CustomWidthTooltip
              title={`Local timezone: UTC ${dayjs().format("Z")}`}
              placement="top"
              arrow
            >
              <TopContainerText>
                  Based on results last updated: Today, {topStore.lastUpdated} <FiberManualRecordIcon />
                </TopContainerText>
            </CustomWidthTooltip>
          </TopContainer>
        ) : (<Container/>)}
        <Group $isSticky={isSticky}>
          <DownloadButton 
            disabled={store.isDownloadDisabled}
            variant="outlined"
            onClick={props.onPdfDownload}
            startIcon={<DownloadIcon />}
          >
            Download Results
            </DownloadButton>
          </Group>
      </TopWrapper>
      {renderer.render()}
    </Container>
  );
});

export default PrioritizeScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const TopWrapper = styled.div<{ $isSticky: boolean }>`
  min-height: 88px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  position: relative;
`;

const TopContainer = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);
`;

const Group = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DownloadButton = styled(Button) <{ $isSticky: boolean }>`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  margin-left: 10px !important;
  align-items: center;
  justify-content: center;
  background-color: ${props => !props.disabled ? 'var(--colorNeutralBackground1)' : 'transparent'} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});