import { FC, useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { Box, Dialog, DialogContent } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import store from "./store";
import styled from "styled-components";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";
import CustomDateField from "./../../../components/customDateField";
import CustomTimeField from "./../../../components/customTimeField";

type SchedulePopupProps = {
  open: boolean;
  hide: (state: boolean) => void;
};

const SchedulePopup: FC<SchedulePopupProps> = observer((props) => {
  const [datePart, setDatePart] = useState<Dayjs | null>(null);
  const [timePart, setTimePart] = useState<Dayjs | null>(null);
  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);
  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const [timeErrorMessage, setTimeErrorMessage] = useState("");

  const daysCount = store.selected_survey_name ? store.selected_survey_name.split(" ")[1] : "0";

  useEffect(() => {
    if ((props.open && store.date !== datePart) || !store.date) {
      const date = store.date?.startOf("day") || dayjs().startOf("day");

      if (!store.date) {
        store.setupMinTime(date);
      }

      const time = store.date 
        ? dayjs(store.date.format("HH:mm"), "HH:mm") 
        : store.manualTime;

      setDatePart(date);
      setTimePart(time);
    }
  }, [props.open, store.date]);

  useEffect(() => {
    if (store.manualDate !== datePart) {
      setDatePart(store.manualDate);
    }
  }, [store.manualDate]);

  useEffect(() => {
    if (store.manualTime !== timePart) {
      setTimePart(store.manualTime);
    }
  }, [store.manualTime]);

  const handleDateError = (isError: boolean, message: string) => {
    setDateError(isError);
    setDateErrorMessage(message);
  };

  const handleTimeFieldError = (isError: boolean, message: string) => {
    setTimeError(isError);
    setTimeErrorMessage(`You can only schedule this email ${message}`);
  };

  const handleOnClose = () => {
    store.changeValue("isSurveyInfoCompleted", false);

    setDatePart(null);
    setTimePart(null);
    setDateError(false);
    setTimeError(false);

    props.hide(false);
  };

  return (
    <StyledDialog open={props.open} onClose={props.hide}>
      <CloseBtnWrapper>
        <CloseButton
          data-testid={`MilstoneRecepient_ShedulePopUp_CloseBtn`}
          onClick={() => {
            props.hide(false);
          }}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <StyledDialogContent>
        <TitleWrapper>
          <Title data-testid={`MilstoneRecepient_ShedulePopUp_Title`}>
            Schedule for {store.selected_employee_name}
          </Title>
        </TitleWrapper>
        <TitleLabel data-testid={`MilstoneRecepient_ShedulePopUp_Subtitle`}>
          {store.selected_survey_name}
        </TitleLabel>
        <List>
          {!store.selectedEmployeeNoAttr && (
            <ListItem $active={store.activeScheduleType === "auto"}>
              <ListItemText>Auto-schedule {daysCount} {Number(daysCount) > 1 ? "days" : "day"} from their start date</ListItemText>
              <Checked
                $active={store.activeScheduleType === "auto"}
                onClick={() => {
                  if (store.selectedEmployeeNoAttr) return;
                  store.setData("activeScheduleType", "auto");
                }}
                $disabled={store.selectedEmployeeNoAttr}
              >
                {store.activeScheduleType === "auto" && <DoneIcon />}
              </Checked>
            </ListItem>
          )}
          <ListItem $active={store.activeScheduleType === "customDate"}>
            <Box>
              <ListItemText data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_Text`}>
                Manually schedule on fixed date and time
              </ListItemText>
              <ContentManualWrapper $active={store.activeScheduleType === "customDate"}>
                <CalendarWrapper
                  data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_InputContainer`}
                >
                  <CustomDateField
                    id="MilstoneRecepient_ShedulePopUp_Body_1_Datefield"
                    value={datePart}
                    changeValue={(value: Dayjs) => store.mergeDateAndTime(value, null)}
                    disabled={store.activeScheduleType !== "customDate"}
                    onError={handleDateError}
                  />
                  <CustomTimeField
                    value={timePart}
                    disabled={store.activeScheduleType !== "customDate"}
                    changeValue={(value: Dayjs) => store.mergeDateAndTime(datePart, value)}
                    minTime={store.minTime}
                    pairWithDate={true}
                    onError={handleTimeFieldError}
                  />
                </CalendarWrapper>

                {store.activeScheduleType === "customDate" && dateError && (
                  <LabelErrorMessage>{dateErrorMessage}</LabelErrorMessage>
                )}
                {store.activeScheduleType === "customDate" && timeError && (
                  <LabelErrorMessage>{timeErrorMessage}</LabelErrorMessage>
                )}

                <CalendarDescription
                  data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_InputInfo`}
                >
                  All selected times are set according to your local timezone: {store.timezone}.
                </CalendarDescription>
              </ContentManualWrapper>
            </Box>
            <Checked
              data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_Checkbox`}
              $active={store.activeScheduleType === "customDate"}
              onClick={() => store.setData("activeScheduleType", "customDate")}
            >
              {store.activeScheduleType === "customDate" && <DoneIcon />}
            </Checked>
          </ListItem>
          <ListItem $active={store.activeScheduleType === "unscheduled"}>
            <ListItemText>Keep it unscheduled (I’ll decide later)</ListItemText>
            <Checked
              $active={store.activeScheduleType === "unscheduled"}
              onClick={() => store.setData("activeScheduleType", "unscheduled")}
            >
              {store.activeScheduleType === "unscheduled" && <DoneIcon />}
            </Checked>
          </ListItem>
        </List>
        <ButtonWrapper>
          <SendCustomButton
            variant="contained"
            disabled={
              (store.activeScheduleType === "customDate" &&
                store.date < dayjs().add(15, "minute")) ||
              dateError
            }
            type="submit"
            onClick={() => {
              store.SaveSchedule();
            }}
          >
            Save changes
          </SendCustomButton>
          <CancelCustomButton variant="outlined" type="reset" onClick={handleOnClose}>
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default SchedulePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100% !important;
    max-width: 522px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px !important;
  }
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0px 60px 0px;
`;

const SendCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 130px;
  height: 34px;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border-color: var(--colorNeutralForeground2) !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
  margin: 10px 0px 20px 0px;
`;

const TitleLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  padding-bottom: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div<{ $active?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 48px;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorPaletteBlueBackground1)"};
  padding: 11px 10px;
  margin-bottom: 10px;
`;

const ListItemText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-top: 5px;
`;

const Checked = styled.span<{ $active?: boolean; $disabled?: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$active
      ? "var(--colorBrandForeground1)"
      : props.$disabled
      ? "var(--colorNeutralForeground2)"
      : "var(--colorNeutralBackground3)"};
  cursor: ${(props) => !props.$disabled && "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    color: var(--colorNeutralBackground1);
  }
`;

const ContentManualWrapper = styled.div<{ $active?: boolean }>`
  display: ${(props) => (props.$active ? "block" : "none")};
`;

const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  column-gap: 8px;
`;

const StyledCalendar = styled(DateTimePicker)`
  width: 100%;
  max-width: 246px;
  border-radius: 2px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1) !important;

  :before {
    border-bottom: none !important;
  }
  :after {
    border-bottom: none !important;
  }

  input {
    padding: 7px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  label {
    position: absolute;
    top: -8px;
  }
`;

const CalendarDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
  padding-top: 8px;
`;

const LabelErrorMessage = styled.p`
  color: var(--colorPaletteRedForeground2);
  padding-top: 8px;
  font-size: 14px;
`;
