import { observer } from "mobx-react";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import styled, { css } from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

import { DETRACTORS, PROMOTERS } from "constants/dashboard-overview";


import { CardLoader } from "features/Dashboard/CardLoader";


import store from "../../store";
import EnpsTable from "./EnpsTable";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE } from "constants/config";

type EnpsCardProps = {};

const colorGraphData = {
  Promoters: {
    color: "var(--colorPaletteGreenBackground1)",
    icon: "/assets/dashboard_overview_funny_smile.png",
  },
  Passives: {
    color: "var(--colorNeutralForeground5)",
    icon: "/assets/dashboard_overview_neutral_smile.png",
  },
  Detractors: {
    color: "var(--colorPaletteRedForeground3)",
    icon: "/assets/dashboard_overview_angry_smile.png",
  },
};

const colorGraphDataFunc = (name: string) => {
  return colorGraphData[name];
};

const EnpsCard: FC<EnpsCardProps> = observer(() => {
  const { isENPSEmpty } = store;

  const graphData = useMemo(() => {
    if (store.projectInfo && store.projectInfo.enps) {
      return store.projectInfo?.enps?.categories.map((el) => {
        const variantColorsIcons = colorGraphDataFunc(el.name);
        return {
          name: el.name,
          count: el.count,
          color: variantColorsIcons.color,
          y: el.percent,
          iconURL: variantColorsIcons.icon,
        };
      });
    } else return [];
  }, [store?.projectInfo?.enps]);

  const returnRoundValue = () => {
    if (isENPSEmpty) {
      return "–";
    }
    const promoters = store.projectInfo?.enps?.categories.find((el) => el.name === PROMOTERS);
    const detractors = store.projectInfo?.enps?.categories.find((el) => el.name === DETRACTORS);
    if (promoters && detractors) return promoters.percent - detractors.percent;
    else return 0;
  };

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      height: 180,
      spacingLeft: 0,
      events: {
        render: function () {
          const chart = this;
          const textX = chart.plotLeft + chart.series[0].center[0];
          const textY = chart.plotTop + chart.series[0].center[1];

          if (chart.centerText) chart.centerText.destroy();

          chart.centerText = chart.renderer
            .html(
              `<div 
                style="display:flex;
                width: 100%; 
                flex-direction:column; 
                align-items: center; 
                justify-content: center;">
                <p  
                  style="font-size: 32px">
                  ${returnRoundValue()}
                </p>
                ${store.projectInfo?.enps?.prevSurveyId > 0
                ? `<p 
                style="font-size: 14px; 
                text-align: center;
                z-index: 1;">
                <img style="margin-right: 4px;" src="${store.projectInfo?.enps && store.projectInfo?.enps?.percent_trend >= 0
                  ? `/assets/green-arrow.svg`
                  : `/assets/red-arrow.svg`
                }"/>${(store.projectInfo?.enps &&
                  Math.abs(store.projectInfo?.enps?.percent_trend)) ||
                "0"
                }
              </p>`
                : ""
              }
              </div>`,
              textX,
              store.selectedSurvey?.isFirst ? textY - 4 : textY - 10
            )
            .add();

          chart.centerText.attr({
            x: textX - chart.centerText.getBBox().width / 2,
          });
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: "",
    },

    legend: {
      squareSymbol: false,
      symbolRadius: 0,
      layout: "vertical",
      align: "right",
      useHTML: true,
      verticalAlign: "middle",
      symbolWidth: 0,
      labelFormatter: function () {
        const value = isENPSEmpty ? "-" : `${this.y}%`
        const count = isENPSEmpty ? "" : `(${this.count})`

        return `
          <span style="display: flex; align-items: center;">
            <img src="${this.iconURL}" style="width: 24px; height:24px; margin-right: 6px"/>
            <p style="margin-right: 4px">${value}</p>
            <p>${this.name}</p> 
            <p style="margin-left: 4px">${count}</p>
          </span>
        `;
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        tooltip: {
          viible: false,
        },
      },
    },
    series: [
      {
        name: "",
        color: "var(--colorNeutralForeground2)",
        lineWidth: 1,
        size: "100%",
        innerSize: "85%",
        borderRadius: 0,
        data: graphData,
      },
    ],
  };

  const content = (
    <>
      {!isENPSEmpty && IS_OLD_DASHBOARD_OR_ENABLED_FEATURE && (
        <ViewLink
          onClick={() => {
            store.setData("hotspots", "activeTab");
          }}
        >
          View response count
        </ViewLink>
      )}

      <HighchartsReact highcharts={Highcharts} options={options} />

      {/* <BenchmarkContainer>
        <BenchmarkText>Benchmark:</BenchmarkText>

        {(store.projectInfo?.enps && store.projectInfo?.enps.benchmark_trend) ||
          (store.projectInfo?.enps && store.projectInfo?.enps.benchmark_trend === 0) ? (
          <>
            <BenchmarkPercent>
              {store.projectInfo?.enps && store.projectInfo?.enps?.benchmark_trend > 0 && "+"}
              {(store.projectInfo?.enps && store.projectInfo?.enps.benchmark_trend) || "0"}
            </BenchmarkPercent>

            <BenchmarkOptionPercent>
              ({(store.projectInfo?.enps && store.projectInfo?.enps.benchmark_total) || "0"})
            </BenchmarkOptionPercent>
          </>
        ) : (
          <EmptyBenchmark>Not available</EmptyBenchmark>
        )}
      </BenchmarkContainer>  */}
    </>
  )

  const isPending = store.isEnpsPending || !store.projectInfo

  return (
    <Card>
      <TitleRow>
        <Title>eNPS</Title>
        <CustomWidthTooltip
          title="The employee Net Promoter Score (eNPS) is a concept that based on the Net Promoter Score (NPS). It allows companies to measure and get a quick easy snapshot of employee advocacy within their company."
          placement="top"
          arrow
        >
          <StyledInfoIcon />
        </CustomWidthTooltip>
        {isENPSEmpty && <EmptyStateTitle>eNPS question was not used in this survey.</EmptyStateTitle>}
      </TitleRow>
      <Content>
        <ChartBox>
          <ContentWrapper isEmpty={isENPSEmpty}>
            {isPending ? <CardLoader contentHeight={229} /> : content}
          </ContentWrapper>
        </ChartBox>
        {!isPending && IS_OLD_DASHBOARD_OR_ENABLED_FEATURE && (<EnpsTable />)}
      </Content>
    </Card>
  );
});

export default EnpsCard;

const Card = styled.div`
  width: 68%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);

  @media (max-width: 1439px) {
    width: 50%;
  }
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: var(--colorBrandForeground1);
  font-size: 20px !important;
`;

const EmptyStateTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
  margin-left: auto;
  line-height: 16px;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

const Content = styled.div`
  width: 100%;
  display: flex;
  column-gap: 24px;
  height: calc(100% - 28px);

  @media (max-width: 1439px) {
    flex-direction: column;
  }
`;

const BenchmarkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const BenchmarkText = styled.p`
  color: var(--colorNeutralForeground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`;

const BenchmarkPercent = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: var(--colorPaletteVioletBackground3);
  margin: 0px 4px -3px 7px;
`;

const BenchmarkOptionPercent = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-bottom: 1px;
`;

const ViewLink = styled.a`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorBrandForeground1);
  transition: all 0.3s;
  text-align: end;

  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 2;

  &:hover {
    color: var(--colorBrandForeground2);
    text-decoration: underline;
  }

  @media (min-width: 1440px) {
    bottom: 30px;
  }
`;

const ChartBox = styled.div`
  width: 50%;
  position: relative;
  height: 100%;

  @media (max-width: 1439px) {
    width: 100%;
  }
`;

const EmptyBenchmark = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
  margin-left: 4px;
`;

type ContentWrapperProps = {
  isEmpty?: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => Boolean(props.isEmpty) && css`
    opacity: 0.4;
    pointer-events: none;
  `}
`;
