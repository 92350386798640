import { FC } from "react";
import {
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { TColumn, TEmployee } from "types/recipients";
import { EntityUser, InvitedUser, User } from "types/permission";

interface CustomTableProps {
  data: InvitedUser[] | User[] | EntityUser[];
  columns: TColumn[];
  noDataTitle?: {
    title?: string;
    description?: string;
  };
  onGetIsRowSelected?: (row: any) => boolean;
  changeHandler?: (selected: boolean, row: TEmployee | InvitedUser | User | EntityUser) => void;
}

const CustomTable: FC<CustomTableProps> = ({
  data,
  columns,
  onGetIsRowSelected,
  changeHandler,
  noDataTitle,
}) => {
  const handleRowClicked = (row: any) => {
    if (onGetIsRowSelected && changeHandler) {
      const isSelected = onGetIsRowSelected(row);
      changeHandler && !row.export && changeHandler(isSelected, row);
    }
  };

  return (
    <StyledContainer>
      {data.length === 0 ? (
        <StyledContainerEmpty>
          <div>
            <h2 id="Table_noData_Title">{noDataTitle.title}</h2>
            <p id="Table_noData_Description">{noDataTitle.description}</p>
          </div>
        </StyledContainerEmpty>
      ) : (
        <StyledTable id="Custom_Table_Container">
          <TableHead id={"Custom_Table_Head"}>
            <StyledTableRowOne id={"Custom_Table_Head_Tr"}>
              {columns?.map((column, i) => (
                <StyledTableCell
                  id={`Custom_Table_Head_Th+${i}`}
                  style={{ width: column.width }}
                  key={column.id}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRowOne>
          </TableHead>
          <TableBody id={"Custom_Table_Body"}>
            {data?.map((row, i) => (
              <StyledTableRow
                id={`Custom_Table_Body_Tr${i}`}
                key={i}
                style={{
                  cursor: row.export ? "not-allowed" : "pointer",
                }}
                onClick={() => handleRowClicked(row)}
              >
                {columns.map((column) => (
                  <StyledTableCell id={`Custom_Table_Cell_${column.id}_${i}`} key={column.id}>
                    {column.render ? (
                      column.render(row)
                    ) : (
                      <StyledTableCellForData>
                        {row[column.id]?.length > 25 ? (
                          <Tooltip
                            title={
                              <Typography variant="body2" style={{ fontSize: "14px" }}>
                                {String(row[column.id])}
                              </Typography>
                            }
                            children={
                              <span id={`Custom_Table_${column.id}_${i}_Text`}>{`${row[
                                column.id
                              ].substring(0, 25)}...`}</span>
                            }
                          />
                        ) : (
                          row[column.id]
                        )}
                      </StyledTableCellForData>
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
    </StyledContainer>
  );
};

const StyledTable = styled(MuiTable)`
  min-width: 650px;
`;

const StyledContainerEmpty = styled("div")`
  background: var(--ER-Theme-white, #fff);
  height: 350px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    color: var(--Blackhole, #3e4450);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }

  p {
    color: var(--ER-Theme-sys-light-on-tertiary-fixed-variant, #959ba2);
    text-align: center;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const StyledContainer = styled(TableContainer)`
  width: 100%;
`;

const StyledTableRowOne = styled(TableRow)`
  &:nth-child(even) {
    width: 100px;
    background-color: #1a227e1a;
  }
  height: 56px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--ER-Theme-sys-light-outline, #f1f3f8);
  background: var(--ER-Theme-sys-light-inverse-on-surface, #e4e9ff);
`;

const StyledTableRow = styled(TableRow)`
  width: 100%;
  cursor: pointer;

  border-bottom: 1px solid var(--ER-Theme-sys-light-outline, #f1f3f8);
  background: var(--ER-Theme-white, #fff);
  height: 56px;
  &:nth-of-type(even) {
    background: var(--ER-Theme-sys-light-on-secondary, #f9fafc);
  }
  &:hover {
    background: var(--ER-Theme-sys-light-on-secondary, rgba(0, 0, 0, 0.05));
  }
`;

const StyledTableCell = styled(TableCell)`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  > td {
    padding: 0;
  }
`;

const StyledTableCellForData = styled(TableCell)`
  padding: 8px 8px 8px 20px;
  border: none;
`;

export default CustomTable;
