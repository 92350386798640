import { TNilCount, TOpenEndedGraphData, TOpenEndedResponse } from "types/dashboard";
import PieChart from "./PieChart";
import store from "./store";
import styled from "styled-components";
import ResponseCard from "./ResponseCard";
import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
import { TooltipProps, tooltipClasses } from "@mui/material";

interface OpenEndedElementRendererConfig {
    dataChart : TOpenEndedGraphData| null;
    respondedCount : number;
    responses: TOpenEndedResponse[];
    localFilters: { id: number; name: string; segments: string[] }[];
    nilCount: TNilCount | number;
}

export class OpenEndedElementRenderer {
    private config: OpenEndedElementRendererConfig;
    private MEANINGLESS_TEXT_MESSAGE = `These responses are hidden because they are less meaningful, for example "nil" or "no comment".`;

    constructor(config: OpenEndedElementRendererConfig) {
        this.config = config;
    }

    private renderPieChart() {
        return  store.isShowSentimentsOpenEndedCard && <PieChart dataChart={this.config.dataChart}/> 
    }

    private getNilCount(sentiment: string): number {
        if (typeof this.config.nilCount === 'number') {
            return this.config.nilCount;
        }
        return this.config.nilCount[sentiment as keyof TNilCount];
    }

    private renderNilCount() {
        const nilCount = this.getNilCount(store.sentimentFilter);
        if (nilCount === 0) {
            return null;
        }

        return (
            <ResponsesNotShownContainer>
                <Label>{nilCount} responses are not shown</Label>
                <TooltipWrapper>
                    <CustomWidthTooltip 
                        title={this.MEANINGLESS_TEXT_MESSAGE}
                        placement="top"
                        arrow
                    >
                        <InfoIcon fontSize="small" />
                    </CustomWidthTooltip>
                </TooltipWrapper>
            </ResponsesNotShownContainer>
        )
    }

    private renderLabel() {
        const nilCount = this.getNilCount(store.sentimentFilter);
        const respondedCount = this.config.respondedCount || 0;
        const totalCount = respondedCount + nilCount;

        return (
            <LabelContainer>
                <Label>{totalCount} responses</Label>
                {nilCount > 0 && this.renderNilCount()}
            </LabelContainer>
        );
    }

    private renderResponses() {
        const responsesList: React.ReactNode[] = [];
        this.config.responses.forEach((response, index) => {
            responsesList.push(
                <ResponsesList>
                    <ResponseCard
                        key={response.Id}
                        response={response}
                        isShowSentimentsOpenEndedCard={store.isShowSentimentsOpenEndedCard}
                        localFilters={this.config.localFilters}
                />
            </ResponsesList>
        )   
        });
        responsesList.push(<Spacing/>);
        return responsesList;   
    }

    public render() {
        return [this.renderPieChart(), this.renderLabel(), ...this.renderResponses()]
    }
}

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 32px 0px 4px 0px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: var(--colorNeutralForeground2);
  margin: 0;
`;

const ResponsesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Spacing = styled.div`
  display: block;
  width: 100%;
  height: 32px;
  flex-shrink: 0;
`;

const ResponsesNotShownContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipWrapper = styled.div`
  margin-left: 8px;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 150,
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  }
});

const InfoIcon = styled(Info)`
  width: 6px;
  height: 6px;
  margin-right: 8px;
  color: var(--colorPaletteVioletBackground2);
  font-size: 16px;
`;