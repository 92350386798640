import { makeAutoObservable } from "mobx";
import { getUsersGroupedByRoles } from "../../api/go/useGetUsersGroupedByRoles";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { EntityUser, EntityUsers, SelectedItem, RoleUser, User } from "types/permission";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";
import { getEntityUsers } from "api/entityPermission/useGetEntityUsers";
import { getEntityUserRoleData } from "api/entityPermission/useGetRoleData";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";
import { revokeUsersPermission } from "api/entityPermission/useRevokeUsers";

//TODO: Remove comment below once all entity tabs work
//This store is used for Entity Permission Refinement
class Store {
  invitedUsers: EntityUsers;
  selectedData: EntityUser[] | [] = [];
  currentUser: User = null;
  activeTab: string = "entityUsers";
  currentRoleName = "";

  currentUserId: number = 0;
  currentRoleId = 0; //TODO: need to be removed once re-assign user to role functionality works
  tabId: number = 0;

  addEditRoleNamePanel = false;
  changeRolePanel = false;
  deleteRolePanel = false;
  removeFromRolePanel = false;
  checkEmployeePanel = false;

  sortAttributeId: number = null;

  currentPage: number = 1;
  pageSize: number = 10;
  sortType: string = "asc";
  sortField: string = "";
  filterByRoles: string = "";
  searchedValue = "";
  resultSearchValue = "";

  roleData: SelectedItem[];
  featureFlag: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  changeResultSearchValue = (value: string) => {
    this.resultSearchValue = value;
  };

  changeSearchedValue = (value: string) => {
    this.searchedValue = value;
  };

  changeTabId = (id: number) => {
    this.tabId = id;
  };

  changeCurrentUser = (user: User) => {
    this.currentUser = user;
  };

  changeSelectedData = (data: EntityUser[]) => {
    this.selectedData = data;
  };

  changeCurrentRoleName = (name: string) => {
    this.currentRoleName = name;
  };

  changeCurrentRoleId = (id: number) => {
    this.currentRoleId = id;
  };

  changeAddEditRoleNamePanel = (flag: boolean) => {
    this.addEditRoleNamePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
    }
  };

  changeDeleteRolePanel = (flag: boolean) => {
    this.deleteRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeCheckEmployeePanel = (flag: boolean) => {
    this.checkEmployeePanel = flag;

    if (!flag) {
      this.currentUserId = 0;
    }
  };

  changeRemoveFromRolePanel = (flag: boolean) => {
    this.removeFromRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
      this.selectedData = [];
    }
  };

  changeChangeRolePanel = (flag: boolean) => {
    this.changeRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  setData = (value: any, field: string) => {
    this[field] = value;
  };

  onSaved = () => {
    this.changeAddEditRoleNamePanel(false);
  };

  onRemovedFromRole = () => {
    this.changeRemoveFromRolePanel(false);
  };

  onRoleDeleted = () => {
    this.changeDeleteRolePanel(false);
    this.tabId = 0;
  };

  onInvitedUsers = () => {
    this.loadEntityUsers();
  };

  keyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.onSearchClicked();
    }
  };

  onSearchClicked = () => {
    this.currentPage = 1;
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";

    this.loadEntityUsers();
  };

  clearStore() {
    this.roleData = [];
    this.selectedData = [];
  }

  changeSort = (field: string, type: string) => {
    this.sortField = field;
    this.sortType = type;

    this.loadEntityUsers();
  };

  async loadEntityUsers() {
    try {
      const response = await getEntityUsers(
        StoreLayout.currentEntityId,
        this.filterByRoles,
        this.currentPage,
        this.pageSize,
        this.sortType,
        this.sortField,
        this.resultSearchValue
      );

      this.invitedUsers = response.data;
      this.currentPage = this.invitedUsers.current_page;
      this.pageSize = this.invitedUsers.page_size;

      if (this.resultSearchValue) {
        this.resultSearchValue = "";
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadEntityRoleData() {
    try {
      const response = await getEntityUserRoleData(StoreLayout.currentEntityId);

      this.roleData = response.data.user_roles;
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadFeatureFlagStatus() {
    try {
      const response = await getFeatureFlagStatus("entity_permission_v2");

      if (response) {
        this.featureFlag = response.data;
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async revokeUserAccess(onRevoked: () => void) {
    const emails = this.selectedData.map((user) => user.email);
    
    try {
      const response = await revokeUsersPermission(emails, StoreLayout.currentEntityId);

      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.selectedData = [];
        this.loadEntityUsers();

        if (emails.length > 1) {
          MainStore.setSnackbar(`Revoked access for ${response.data.count} users from ${StoreLayout.currentEntityName}`)
        } else {
          MainStore.setSnackbar(`Revoked access for ${response.data.user_emails[0]} from ${StoreLayout.currentEntityName}`)
        }

        onRevoked();
      } else {
        throw new Error();
      }

    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }
}

const store = new Store();
export default store;
