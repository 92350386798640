import { observer } from "mobx-react";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { FC, useCallback, useEffect, useState } from "react";
import { RESPONSES_TAB_MENU } from "constants/dashboard-menu";
import { Button, MenuItem, Select, Switch} from "@mui/material";

import dayjs from "dayjs";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import topStore from "../store";
import responsesStore from "./store";
import FlagIcon from "@mui/icons-material/Flag";
import OthersTab from "./OthersTab/OthersTab";
import EmptyLabel from "./EmptyLabel";
import OpenEndedTab from "./OpenEndedTab/OpenEndedTab";
import ScoredQuestionsTab from "./ScoredQuestionsTab/ScoredQuestionsTab";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE } from "constants/config";
import DownloadIcon from '@mui/icons-material/Download';
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import IconButton from '@mui/material/IconButton';

type ResponsesScreenProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
  onPdfDownload: () => void;
};

const ResponsesScreen: FC<ResponsesScreenProps> = observer((props) => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = useCallback((el: HTMLElement) => {
    const scrollTop = el.scrollTop;
    const threshold = 80;

    requestAnimationFrame(() => {
      setIsSticky(scrollTop > threshold);
    });
  }, []);

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");
    if (!el) return;

    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          handleScroll(el);
          ticking = false;
        });
        ticking = true;
      }
    };

    el.addEventListener("scroll", scrollHandler, { passive: true });

    return () => {
      el.removeEventListener("scroll", scrollHandler);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (topStore.selectedSurvey) {
      topStore.getSurveyResponseCount(props.localFilters);
    }
  }, [
    topStore.selectedSurvey,
    StoreLayout.currentWorkspaceId,
    StoreLayout.currentEntityId,
    props.localFilters
  ]);

  const renderActiveTab = useCallback(() => {
    switch (topStore.responsesActiveLocalTab) {
      case "scored_questions":
        return <ScoredQuestionsTab localFilters={props.localFilters} />;
      case "open_ended":
        return <OpenEndedTab localFilters={props.localFilters} />;
      case "others":
        return <OthersTab localFilters={props.localFilters} />;
      default:
        return <ScoredQuestionsTab localFilters={props.localFilters} />;
    }
  }, [topStore.responsesActiveLocalTab, props.localFilters]);

  const returnTooltipText = (tab: string) => {
    switch (tab) {
      case "scored_questions":
        return `No scored questions were used in this survey.`;
      case "open_ended":
        return `No open-ended questions were used in this survey.`;
      case "others":
        return `Only scored and open-ended questions were used in this survey.`;
      default:
        return `No scored questions were used in this survey.`;
    }
  };

  const returnDisableTab = (tab: string) => {
    switch (tab) {
      case "scored_questions":
        return topStore.isResponseScoredTabDisplay;
      case "open_ended":
        return topStore.isResponseOPenEndedTabDisplay;
      case "others":
        return topStore.isResponseOthersTabDisplay;
      default:
        return false;
    }
  };

  const handleDownloadPDF = async () => {
    props.onPdfDownload();
  };

  return (
    <Container>
      <TopWrapper $isSticky={isSticky}>
        {IS_OLD_DASHBOARD_OR_ENABLED_FEATURE ? (
          <TopContainer $isSticky={isSticky}>
            <TopContainerText>
              Viewing results for {topStore.calculateRespondentsCount} respondents
            </TopContainerText>
            <Divider>|</Divider>
            <CustomWidthTooltip
              title={`Local timezone: UTC ${dayjs().format("Z")}`}
              placement="top"
              arrow
            >
              <TopContainerText>
                Based on results last updated: Today, {topStore.lastUpdated} <FiberManualRecordIcon />
              </TopContainerText>
            </CustomWidthTooltip>
          </TopContainer>
        ) : (<Container/>)}
         <Group>
            <DownloadButton 
              variant="outlined"
              disabled={!topStore.isSurveyHaveResponseCount}
              onClick={handleDownloadPDF}
              startIcon={<DownloadIcon />}
            >
            Download Results
            </DownloadButton>
          </Group>
      </TopWrapper>
      {!topStore.isSurveyHaveResponseCount ? <EmptyLabel /> :
      <Content className="responses-content">
        <Menu>
          {RESPONSES_TAB_MENU.map((menuItem, index) => {
            const isDisabled = returnDisableTab(menuItem.value);
            return (
              <CustomWidthTooltip
                key={index + menuItem.value}
                title={returnTooltipText(menuItem.value)}
                placement="top"
                arrow
                disableHoverListener={isDisabled}
              >
                <CustomMenuItem
                  $active={Boolean(topStore.responsesActiveLocalTab === menuItem.value)}
                  $last={index === topStore.responsesActiveLocalTab.length}
                  $disabled={!isDisabled}
                  onClick={() =>
                    isDisabled && topStore.setData(menuItem.value, "responsesActiveLocalTab")
                  }
                >
                  {menuItem.label}
                </CustomMenuItem>
              </CustomWidthTooltip>
            );
          })}
        </Menu>
        {renderActiveTab()}
      </Content>
    }
    </Container>
  );
});

export default ResponsesScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const TopWrapper = styled.div<{ $isSticky: boolean }>`
  min-height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 8px 0px;
  position: relative;
  opacity: 1;
  visibility: visible;
`;

const TopContainer = styled.div<{ $isSticky: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  opacity: 1;
  visibility: visible;

  @media (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  cursor: default;

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: var(--colorNeutralForegroundInverted1);
  padding-top: 12px;
`;

const CustomMenuItem = styled.span<{ $active?: boolean; $last?: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  padding: 8px 20px;
  color: ${(props) =>
    props.$disabled
      ? "var(--colorNeutralForeground5)"
      : props.$active
        ? `var(--colorNeutralForeground1)`
        : `var(--colorNeutralForeground2)`};
  border-radius: 4px 4px 0px 0px;
  ${(props) =>
    !props.$active
      ? `border: 1px solid var(--colorPaletteBlueBackground1)`
      : `border: 1px solid var(--colorNeutralBackground1)`};
  ${(props) => props.$active && `border: 1px solid var(--colorBrandForeground1)`};
  background-color: ${(props) =>
    props.$disabled
      ? "var(--colorGrayForeground6)"
      : props.$active
        ? `var(--colorNeutralBackground1)`
        : `transparent`};
  transition: all 0.3s;
  margin-right: 8px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);

  @media (max-width: 1439px) {
    display: none;
  }
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  margin-left: 10px !important;
  background-color: ${props => !props.disabled ? 'var(--colorNeutralBackground1)' : 'transparent'} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const FilterBox = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 0px 8px 0px 4px;
  white-space: nowrap;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: var(--colorPaletteVioletBackground3);
  width: 18px !important;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 128px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  svg {
    font-size: 16px;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px !important;
  font-weight: 400 !important;
`;

const StyledSwitch = styled(Switch)`
  max-width: 60px !important;
  margin-right: -12px;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid var(--colorNeutralBackground3);
`;

const DialogHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CloseButton = styled(IconButton)`
  padding: 4px !important;
  
  svg {
    font-size: 24px;
    color: var(--colorNeutralForeground1Hover);
  }
`;

const DialogTitle = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--colorNeutralForeground1Hover);
`;

const DownloadPDFButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  background-color: ${props => !props.disabled ? 'var(--colorNeutralBackground1)' : 'transparent'} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;