import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Stack, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CustomModal from "components/modal/Modal";
import CustomButton from "components/button/Button";
import { SelectedItem, RoleUser, UserRole } from "types/permission";
import DropdownPopOver from "components/dropdownPopOver";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface ReassignRolesModalProps {
  id: string;
  open: boolean;
  currentRoleNames: string[];
  emails: string[];
  options: SelectedItem[];
  onClose: () => void;
  reassignHandler: () => void;
  selectRoleHandler: (roleIds: number[]) => void;
  deleteRole: (index: number) => void;
}

const ReassignRolesModal = ({
  id,
  open,
  currentRoleNames,
  emails,
  options,
  onClose,
  reassignHandler,
  selectRoleHandler,
  deleteRole,
}: ReassignRolesModalProps) => {
  const [isRoleSelected, setIsRoleSelected] = useState<string>("");
  const [selectedRoles, setSelectedRoles] = useState<UserRole[] | null>(null);

  const [currentEl, setCurrentEl] = useState<HTMLElement | null>(null);
  const [openRolePopup, setopenRolePopup] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelectedRoles(null);
      setIsRoleSelected("");
    }
  }, [open]);

  useEffect(() => {
    if (selectedRoles && selectedRoles.length < 1) {
      setIsRoleSelected("Please select at least one user role for each user.");
      return;
    }

    setIsRoleSelected("");
  }, [selectedRoles]);

  const handleSelectOptions = (selectedItems: SelectedItem[]) => {
    setSelectedRoles(selectedItems);

    selectRoleHandler(selectedItems.map((role) => role.id));
  };

  const deleteSelectedRole = (index: number) => {
    const updatedRoles = [...selectedRoles.slice(0, index), ...selectedRoles.slice(index + 1)];
    setSelectedRoles(updatedRoles);

    deleteRole(index);
  };

  const distinctRoleNames = useMemo(() => {
    return Array.from(new Set(currentRoleNames)).join(", ");
  }, [currentRoleNames]);
  
  return (
    <CustomModal id={id} open={open} hide={onClose} width="600px" padding="0">
      <FormWrapper>
        <Header data-testid="EntityPermissions_Revoke_User_popup_title">Re-assign user role</Header>
        <AddedEmailBox>
          <EmailsCountLabel>
            Users in role ({emails.length} {""} {emails.length > 1 ? "users" : "user"}):
          </EmailsCountLabel>
          {emails?.length > 0 && <EmailLabels>{emails.join(", ")}</EmailLabels>}
        </AddedEmailBox>

        <CurrentRoleLabel>Current user role(s)</CurrentRoleLabel>

        <CurrentRoleListLabel>{distinctRoleNames}</CurrentRoleListLabel>

        <RolesAssignWrapper>
          <SubHeader>Re-assign user role(s) from dropdown</SubHeader>
          <SelectionWrapper>
            <SelectionContainer
              onClick={(event) => {
                setopenRolePopup(true);
                setCurrentEl(event.currentTarget);
              }}
            >
              <p>Select user roles(s)</p>
              <KeyboardArrowDownIconStyle />
            </SelectionContainer>
            <DropdownPopOver
              anchorElement={currentEl}
              isOpen={openRolePopup}
              items={options}
              updateSelectedItems={selectedRoles}
              onSelect={(items) => handleSelectOptions(items)}
              onClose={() => {
                setopenRolePopup(false);
                setCurrentEl(null);
              }}
              anchorPosition={{ vertical: "bottom", horizontal: "left" }}
              customStyle={{ width: `${currentEl?.clientWidth}px`, maxHeight: "288px" }}
            ></DropdownPopOver>
          </SelectionWrapper>

          <LabelError>{isRoleSelected}</LabelError>
        </RolesAssignWrapper>

        <StyledStack direction="row" spacing={{ xs: 1, sm: 1 }} useFlexGap flexWrap="wrap">
          {selectedRoles?.map((value, index) => (
            <Chip
              data-testid="EntityPermissions_Selected_Roles_Chip"
              color="primary"
              size={"medium"}
              variant="outlined"
              label={value.name}
              deleteIcon={<ClearIcon />}
              onDelete={() => deleteSelectedRole(index)}
              sx={{
                fontSize: "14px",
                weight: "500",
                lineHeight: "20px",
              }}
            />
          ))}
        </StyledStack>
      </FormWrapper>

      <BtnWrp>
        <CustomButton
          data-testid="EntityPermissions_AddUsers_popup_AddBtn"
          onClick={reassignHandler}
          variant="contained"
          color="primary"
          disabled={isRoleSelected !== "" || !selectedRoles}
        >
          Re-assign user role
        </CustomButton>
        <CancelButton
          data-testid="EntityPermissions_AddUsers_popup_CanselBtn"
          onClick={onClose}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </CancelButton>
      </BtnWrp>
    </CustomModal>
  );
};

const FormWrapper = styled.div`
  padding: 60px 60px 8px 40px;
`;

const RolesAssignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const BtnWrp = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 60px 48px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 24px;
`;

const SubHeader = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 0 0 8px;
`;

const SelectionWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SelectionContainer = styled.div`
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  color: var(--colorNeutralForeground5);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const CurrentRoleLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 24px 0 8px;
`;

const CurrentRoleListLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 8px;
`;

const AddedEmailBox = styled.div`
  height: fit-content;
  max-height: 200px;
  overflow: auto;
  border: 1px solid var(--colorNeutralBackground3);
  background: var(--colorPaletteGrayBackground1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorNeutralForeground5);
  }
`;

const EmailsCountLabel = styled.p`
  font-weight: 500px;
  size: 14px;
  line-height: 20px;
  padding-bottom: 4px;
`;

const EmailLabels = styled(Stack)`
  font-weight: 400px;
  size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const LabelError = styled.p`
  font-weight: 400px;
  size: 14px;
  line-height: 20px;
  color: var(--colorPaletteRedForeground2);
  padding-top: 4px;
`;

const StyledStack = styled(Stack)`
  margin-top: 10px;
`;

const CancelButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  width: 20px !important;
  height: 20px !important;

  &:hover {
    background-color: transparent;
  }
`;

export default ReassignRolesModal;
