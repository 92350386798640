import { makeAutoObservable } from "mobx";
import { inviteUsersToRoles } from "api/entityPermission/useInviteUsersToRoles";
import MainStore from "MainStore";
import { resendInvitationUsersToRoles } from "api/entityPermission/useResendInvinteUsers";
import { assignUsersToRoles } from "api/entityPermission/useAssignUsersToRoles";

class Store {
  emails: string[] = [];
  roles: number[] = [];
  emailValue: string;

  constructor() {
    makeAutoObservable(this);
  }

  changeEmailValue = (value: string) => {
    this.emailValue = value;
  };

  addEmail = () => {
    this.emails = [...this.emails, this.emailValue];
    this.emailValue = "";
  };

  changeEmails = (emails: string[]) => {
    this.emails = emails;
  };

  deleteEmail = (index: number) => {
    this.emails = [...this.emails.slice(0, index), ...this.emails.slice(index + 1)];
  };

  addRole = (roleId: number) => {
    this.roles = [...this.roles, roleId];
  };

  deleteRole = (index: number) => {
    this.roles = [...this.roles.slice(0, index), ...this.roles.slice(index + 1)];
  };

  changeRoles = (roles: number[]) => {
    this.roles = roles;
  };

  clearInviteUserStore = () => {
    this.emails = [];
    this.emailValue = "";
    this.roles = [];
  };

  inviteUserSubmit = async (entityId: number, entityName: string, onSaved: () => void) => {
    try {
      MainStore.changeLoader(true);

      const response = await inviteUsersToRoles(this.emails, this.roles, entityId, 1);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        MainStore.changeLoader(false);

        if (this.emails.length > 1) {
          MainStore.setSnackbar(`${response.data.count} users invited to ${entityName}`);
        } else {
          MainStore.setSnackbar(`${response.data.user_emails[0]} invited to ${entityName}`);
        }

        this.clearInviteUserStore();
        onSaved();
      } else {
        MainStore.setSnackbar(response.response.data?.error, "error");
        throw new Error(response?.response.status || "");
      }
    } catch (err) {
      if (err.message !== "422") {
        MainStore.setSnackbar("Something went wrong!", "error");
      }
    } finally {
      MainStore.changeLoader(false);
    }
  };

  resendInvitation = async (entityId: number) => {
    try {
      MainStore.changeLoader(true);

      const response = await resendInvitationUsersToRoles(this.emails, this.roles, entityId, 1);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        MainStore.changeLoader(false);

        if (this.emails.length > 1) {
          MainStore.setSnackbar(`Invitation sent to ${response.data.count} users`);
        } else {
          MainStore.setSnackbar(`Invitation sent to ${response.data.user_emails[0]}`);
        }

        this.clearInviteUserStore();
      } else {
        MainStore.setSnackbar(response.response.data?.error, "error");
        throw new Error(response?.response.status || "");
      }
    } catch (err) {
      if (err.message !== "422") {
        MainStore.setSnackbar("Something went wrong!", "error");
      }
    } finally {
      MainStore.changeLoader(false);
    }
  };

  reassignToRoles = async (entityId: number, onSaved: () => void) => {
    try {
      const response = await assignUsersToRoles(this.emails, this.roles, entityId, 1);

      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        if (this.emails.length > 1) {
          MainStore.setSnackbar(
            `User roles reassigned successfully for ${response.data.count} users`
          );
        } else {
          MainStore.setSnackbar(
            `User roles reassigned successfully for ${response.data.user_emails[0]}`
          );
        }

        this.clearInviteUserStore();
        onSaved();
      } else {
        MainStore.setSnackbar("Failed to reassign user roles. Please try again", "error");
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to reassign user roles. Please try again", "error");
    }
  };
}

const store = new Store();
export default store;
