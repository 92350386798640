import {
  TRecipientsFilter,
  TRecipientEmployee,
  TEmployee,
  TRecipientLength,
  TRecipientsFilterSearch,
} from "types/recipients";
import { getAllEmployeesRecipients } from "api/go/useGetAllEmployeesRecipients";
import { getAllEmployeesNoRecipients } from "api/go/useGetAllEmployeesNoRecipients";
import { addEmployeeRecipients } from "api/go/useAddEmployeeRecipients";
import { addEmployeeRecipientsRunning } from "api/survey/useAddEmployeeRecipients";
import { getRecepientStatusForRunningMilestone } from "api/useGetRecipientStatusForRunningMilestone";
import { removeEmployeeRecipients } from "api/survey/useRemoveEmployeeRecipients";
import { makeAutoObservable, runInAction } from "mobx";
import { getAttributesByEntity } from "api/go/useGetAttributesByEntity";
import { entityAttributesRecipients } from "types/entityAttributesRecip";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { sm_survey } from "types/surveys";
import { getSurvey } from "api/useGetSurvey";
import headerStore from "features/Survey/SurveyList/store";
import { SURVEY_STATUS } from "constants/survey-status";
import useGetExcelRecepients from "api/useGetExcelRecepients";
import { getRecepientsMilestone } from "api/useGetEmployeesWithMilestones";
import { Employee } from "types/milestone";

class Store {
  id_survey: number = 0;
  Survey: sm_survey = null;
  filters: TRecipientsFilter[] = [
    {
      select_attribute: null,
      select_segments: [],
    },
  ];

  surveyTitle: string = "Title";
  importSearchValue: string = "";
  importSortField: string = "";
  importSortOrder: boolean = true;
  exportSearchValue: string = "";
  exportSortField: string = "";
  exportSortOrder: boolean = true;
  resultimportSearchValue: string = "";
  resultexportSearchValue: string = "";
  activePage: number = 1;
  countPerPage: number = 10;
  exportActivePage: number = 1
  exportCountPerPage: number = 10
  countTotal: number = 0
  exportCountTotal: number = 0

  countTotalAll: number = 0;

  importSelectionRows = [];
  exportSelectionRows = [];

  importRecipientsList: TEmployee[] = [];
  exportRecipientsList: TRecipientEmployee[] = [];
  Attributes: entityAttributesRecipients[] = [];

  addRecipientsListModal: boolean = false;

  is_running_survey: boolean = false;

  selectAllEmployees: boolean = false
  selectAllRecipients: boolean = false

  fgColorForSheet: string = "003296";
  colorForSheet: string = "ededf0";

  resultSearchValue: string = "";

  autoDateRecipientsCount: number = 0;
  customDateRecipientsCount: number = 0;

  importRecipientsLength: TRecipientLength = {
    currentLength: 0,
    totalLength: 0,
  };

  keyPressImport = (e: any) => {
    if (e.keyCode === 13) {
      this.getAllEmployees();
    }
  };

  keyPressExport = (e: any) => {
    if (e.keyCode === 13) {
      this.getAllRecipients();
    }
  };

  setCountPerPage = (num: number) => {
    this.countPerPage = num
  }

  setActivePage = (num: number) => {
    this.activePage = num
  }

  setExportCountPerPage = (num: number) => {
    this.exportCountPerPage = num
  }

  setExportActivePage = (num: number) => {
    this.exportActivePage = num
  }

  searchImportRecipients = (value: string) => {
    this.importSearchValue = value;
    this.resultimportSearchValue = value;
  };

  searchExportRecipients = (value: string) => {
    this.exportSearchValue = value;
    this.resultexportSearchValue = value
  };

  constructor() {
    makeAutoObservable(this);
  }

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  getSurveyInformation = async (id_survey?: number) => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const resp = await getSurvey(this.id_survey || id_survey);
      runInAction(() => {
        this.Survey = resp?.data;
        MainStore.changeLoader(false);
        headerStore.setProjectId(resp?.data.project_id);
        if (resp?.data.status_idCode === SURVEY_STATUS.running) {
          this.is_running_survey = true;
        } else {
          this.is_running_survey = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };

  mappingFilterForRequest = (filterParams: TRecipientsFilter[]): TRecipientsFilterSearch[] => {
    let filter = filterParams
    .filter((x) => x.select_attribute !== null && x.select_segments.length !== 0)
    .map((x) => {
      const attr = this.Attributes.find((y) => y.name === x.select_attribute);
      return {
        attribute_id: attr!.id,
        values: x.select_segments,
      };
    }).reduce((acc, el) => {
        if (acc[el.attribute_id]) {
          acc[el.attribute_id].values = [...acc[el.attribute_id].values, ...el.values];
        } else {
          acc[el.attribute_id] = { ...el };
        }
        return acc;
      }, {});

    return Object.values(filter)
  }

  fetchEmployeesRecipients = async ({
    survey_id = this.id_survey,
    searchValue = this.importSearchValue,
    page = this.activePage,
    limit = this.countPerPage,
    sort = this.importSortField,
    order = this.importSortOrder,
  }: {
    survey_id?: number;
    searchValue?: string;
    page?: number;
    limit?: number;
    sort?: string;
    order?: boolean;
  } = {}) => {
    const filters = this.mappingFilterForRequest(this.filters);
  
    return await getAllEmployeesRecipients(
      survey_id,
      searchValue,
      filters,
      page,
      limit,
      sort,
      order
    );
  };

  getAllEmployees = async (id_survey?: number) => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      
      const resp = await this.fetchEmployeesRecipients({ survey_id: id_survey || this.id_survey})
      if ((resp.status === 200 || resp.status === 201) && resp?.data !== null) {
        runInAction(() => {
          MainStore.changeLoader(false);

          this.importRecipientsList = resp?.data.recipients;
          this.countTotal = resp?.data.count;

          // to pin point all employees count on the first page load, means no filter aplied
          if (this.filters.every((value) => {
            return value.select_attribute === null || value.select_segments.length === 0;
          }) ) {
            this.countTotalAll = resp?.data.count;
          }
        });
      } else throw new Error();
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };

  fetchEmployeesNoRecipients = async ({
    survey_id = this.id_survey,
    searchValue = this.exportSearchValue,
    filters = [],
    page = this.exportActivePage,
    limit = this.exportCountPerPage,
    sort = this.exportSortField,
    order = this.exportSortOrder,
  }: {
    survey_id?: number;
    searchValue?: string;
    filters?: TRecipientsFilterSearch[]
    page?: number;
    limit?: number;
    sort?: string;
    order?: boolean;
  } = {}) => {
    return await getAllEmployeesNoRecipients(
      survey_id,
      searchValue,
      filters,
      page,
      limit,
      sort,
      order
    );
  };

  getAllRecipients = async (id_survey?: number, activePage?: number) => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      
      const currentPage = activePage ?? this.exportActivePage
      const resp = await this.fetchEmployeesNoRecipients({ survey_id: id_survey || this.id_survey, page: currentPage });
      
      if ((resp.status === 200 || resp.status === 201) && resp?.data !== null) {
        runInAction(() => {
          MainStore.changeLoader(false);

          this.exportRecipientsList = resp?.data.recipients;
          this.exportCountTotal = resp?.data.count;

          if (activePage) {
            this.exportActivePage = activePage
          }
        });
      } else throw new Error();
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };

  getRecipientStatusForRunningMilestone = async (idSurvey: number | null = null) => {
    try {
      let id = idSurvey ?? this.id_survey;
      MainStore.changeLoader(true);

      if (id && id !== 0) {
        const response = await getRecepientStatusForRunningMilestone(id);
        if ((response.status === 200 || response.status === 201) && response?.data !== null) {
          this.autoDateRecipientsCount = response.data.auto_date_recipient_count;
          this.customDateRecipientsCount = response.data.custom_date_recipient_count;
        } else throw Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setResetSelectAllEmployee = () => {
    store.setData("selectAllRecipients", false);
    store.setData("importSelectionRows", []);
  }

  loadAttributes = async () => {
    try {
      const resp = await getAttributesByEntity(StoreLayout.currentEntityId);
      runInAction(() => {
        this.Attributes = resp?.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  openRecipientsModal(open: boolean) {
    this.addRecipientsListModal = open;
  }

  closeRecipientsModal(close: boolean) {
    this.addRecipientsListModal = close;
  }

  changeSelectedRecipients(isSelected?: boolean, row?: TEmployee) {
    var newSelection = isSelected
      ? this.importSelectionRows.filter((id) => id !== row.id)
      : [...this.importSelectionRows, row.id];

    
    this.setData("importSelectionRows", newSelection);

    if (this.importSelectionRows.length !== this.importRecipientsList.length) {
      this.setData("selectAllRecipients", false)
    }
  };

  changeSelectedEmploees(isSelected?: boolean, row?: TEmployee) {
    var newSelection = isSelected
      ? this.exportSelectionRows.filter((id) => id !== row.id)
      : [...this.exportSelectionRows, row.id];

      this.setData("exportSelectionRows", newSelection);

    if (this.exportSelectionRows.length !== this.exportRecipientsList.length) {
      this.setData("selectAllEmployees", false)
    }
  };

  addRecipients = async (running: boolean) => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const attributeFilter = this.mappingFilterForRequest(this.filters);

      const resp = await addEmployeeRecipients(
        this.id_survey,
        this.importSelectionRows,
        this.selectAllRecipients,
        attributeFilter,
        this.importSearchValue,
      );

      if (resp.status === 200 || resp.status === 201) {
        runInAction(async () => {
          //TODO refactor to async/await, use one common method
          if (running) {
            const response = await addEmployeeRecipientsRunning(
              this.id_survey,
              this.importSelectionRows
            );
            if (response) {
              this.getAllEmployees();
              this.getAllRecipients();
            }
          } else {
            this.getAllEmployees();
            this.getAllRecipients();
          }

          MainStore.changeLoader(false);
          
          if (this.selectAllRecipients) {
            MainStore.setSnackbar(
              `${this.importSelectionRows.length} successfully added`,
              "success"
            )
          } else {
            MainStore.setSnackbar(
              this.importSelectionRows.length > 1
                ? this.importSelectionRows.length + ` recipients successfully added`
                : '"' +
                this.importRecipientsList.find((el) => el.id === this.importSelectionRows[0])
                  ?.full_name +
                '"' +
                ` successfully added`,
              "success"
            );
          }

          this.importSelectionRows = [];
          this.selectAllRecipients = false
        });
      } else throw new Error();
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };

  removeRecipients = async () => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const resp = await removeEmployeeRecipients(this.id_survey, this.exportSelectionRows, this.selectAllEmployees, this.exportSearchValue);
      if (resp.status === 200 || resp.status === 201) {
        runInAction(() => {
          this.getAllEmployees();
          this.getAllRecipients(this.id_survey, 1);

          MainStore.changeLoader(false);
          
          if (this.selectAllEmployees) {
            MainStore.setSnackbar(
              this.exportSelectionRows.length +
              ` successfully removed`,
              "success"
            )
          } else {
            MainStore.setSnackbar(
              this.exportSelectionRows.length > 1
                ? this.exportSelectionRows.length + ` recipients successfully removed`
                : '"' +
                this.exportRecipientsList.find((el) => el.id === this.exportSelectionRows[0])
                  ?.full_name +
                '"' +
                ` successfully removed`,
              "success"
            );
          }
          this.exportSelectionRows = [];
          this.selectAllEmployees = false
        });
      } else throw new Error();
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };

  addFilter = () => {
    if (this.filters.length === 6) return;
    else {
      this.filters = [
        ...this.filters,
        {
          select_attribute: null,
          select_segments: [],
        },
      ];
    }
  };

  saveExcel = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await useGetExcelRecepients(this.id_survey);
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Survey recepients ${this.Survey?.name}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeFilterValues = (changeIndex: number, field: string, value: string | string[]) => {
    this.filters = this.filters.map((filter, index) => {
      if (index === changeIndex) {
        return {
          ...filter,
          [field]: value,
        };
      }
      return filter;
    });
    if (field === "select_attribute") {
      this.changeFilterValues(changeIndex, "select_segments", []);
    }
    this.checkAndClearOtherFilters();
  };

  removeFilter = (removeIndex: number) => {
    if (this.filters.length === 1) {
      this.filters = [{ select_attribute: null, select_segments: [] }];
    } else {
      this.filters = this.filters.filter((_, index) => index !== removeIndex);
    }
    this.checkAndClearOtherFilters();
  };

  checkAndClearOtherFilters = () => {
    this.Attributes.forEach((attribute) => {
      const selectedSegments = new Set();
      this.filters.forEach((filter) => {
        if (filter.select_attribute === attribute.name) {
          filter.select_segments.forEach((segment) => selectedSegments.add(segment));
        }
      });

      const isAttributeFullySelected = attribute.values?.every((attrValue) =>
        selectedSegments.has(attrValue.value)
      );

      if (isAttributeFullySelected) {
        this.filters.forEach((filter, index) => {
          if (filter.select_attribute === attribute.name && filter.select_segments.length === 0) {
            this.filters[index] = { ...filter, select_attribute: null, select_segments: [] };
          }
        });
      }
    });
  };

  removeSegment = (indexAttribute: number, indexSegment: number) => {
    this.filters = this.filters.map((el, i) => {
      if (i !== indexAttribute) {
        return el;
      }
      return {
        ...el,
        select_segments: el.select_segments.filter((_, i) => i !== indexSegment),
      };
    });
    if (this.filters[indexAttribute].select_segments.length == 0) {
      this.removeFilter(indexAttribute)
      this.getAllEmployees();
    }
  };
}

const store = new Store();
export default store;
