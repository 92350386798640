import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { TOpenEndedQuestion } from "types/dashboard";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import responseStore from "../../responses/store";
import PeopleIcon from "@mui/icons-material/People";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type QuestionsListProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionsList: FC<QuestionsListProps> = observer((props) => {
  useEffect(() => {
    if (topStore.selectedSurvey) {
      store.getDashboardResponsesOpenEndedQuestions(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [topStore.selectedSurvey, props.localFilters]);

  const groupedQuestions = store.openEndedQuestionsList?.reduce((acc: TOpenEndedQuestion[][], question, index) => {
    const groupIndex = Math.floor(index / responseStore.openEndedQuestionGroupItem);
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex].push(question);
    return acc;
  }, []) || [];

  return (
    <Container>
      <Title>Questions</Title>
      {groupedQuestions.map((group, groupIndex) => (
        <GroupContainer key={groupIndex}>
          {group.map((question: TOpenEndedQuestion) => (
            <QuestionWrapper
              key={question.id}
              $active={store.activeOpenEndedQuestion?.id === question.id}
              onClick={() => store.changeQuestions(question)}
            >
              <QuestionTitle $active={store.activeOpenEndedQuestion?.id === question.id}>
                {question.title}
              </QuestionTitle>
              <QuestionText>
                <PeopleIcon />
                {question.respondedCount} responded
              </QuestionText>
            </QuestionWrapper>
          ))}
        </GroupContainer>
      ))}
    </Container>
  );
});

export default QuestionsList;

const Container = styled.div`
  min-width: 180px;
  max-width: 299px;
  padding: 24px 20px;
  position: sticky;
  box-shadow: 8px 2px 12px 0px var(--colorPaletteBlueBackground4);
  max-height: calc(100vh - 267px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const QuestionTitle = styled.p<{ $active?: boolean }>`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${(props) =>
    props.$active ? `var(--colorNeutralBackgroundInverted3)` : `var(--colorNeutralForeground2)`};
`;

const QuestionWrapper = styled.div<{ $active?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 8px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.$active ? `var(--colorNeutralBackground1)` : `var(--colorPaletteGrayBackground1)`};
  border: ${(props) =>
    props.$active
      ? `1px solid var(--colorBrandForeground1)`
      : `1px solid var(--colorPaletteBlueBackground1)`};
  cursor: pointer;
  position: relative;
  transition: all 0.3s;

  ${(props) =>
    props.$active &&
    `
  ::after, ::before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  ::after {
    border-left-color: var(--colorNeutralBackground1);
    border-width: 10px;
    margin-top: -10px;
  }

  ::before {
    border-left-color: var(--colorBrandForeground1);
    border-width: 11px;
    margin-top: -11px;
  }
  `}
`;

const QuestionText = styled.span`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.08px;
  color: var(--colorNeutralForeground1);
  margin-top: 16px;

  svg {
    font-size: 14px;
    color: var(--colorNeutralForeground2);
    margin-right: 4px;
  }
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorPaletteVioletBackground1);
`;

const GroupContainer = styled.div`
  background: var(--colorNeutralBackground2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;
