import { useGetQuestions } from "api/dasboard/useGetQuestion";
import { useGetPrioritizeTableList } from "api/dasboard/useGetPrioritizeTableList";
import { useGetPrioritizeChartData } from "api/dasboard/useGetPrioritizeChartData";
import { makeAutoObservable, runInAction } from "mobx";
import {
    TPrioritizeChartData,
    TPrioritizeDimension,
    TRequestPrioritizeData,
} from "types/dashboard";

import MainStore from "MainStore";

class Store {
    isShowDimensionLabels: boolean = false;

    questionsList: { label: string; value: number }[] = [];
    selectedQuestion: number = null;

    prioritizeList: TPrioritizeDimension[] = [];
    selectedPrioritize: number[] = [];

    isDownloadDisabled: boolean = true;

    dimensionsSortBy: string = "favorable_score";
    dimensionsSortValue: string = "value_asc";

    chartData: TPrioritizeChartData = null;

    constructor() {
        makeAutoObservable(this);
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getQuestionList = async (data: TRequestPrioritizeData) => {
        runInAction(() => {
            this.isDownloadDisabled = false;
        });
        try {
            store.selectedQuestion = null;
            MainStore.changeLoader(true);
            const response = await useGetQuestions(data);
            if (response.status === 200 || response.status === 201) {
                runInAction(() => {
                    this.questionsList = response.data.map((el) => ({ value: el.id, label: el.title }));
                    this.selectedQuestion = response.data[0].id;
                    this.isDownloadDisabled = this.questionsList.length < 3;
                });
                this.selectedPrioritize = [];
            } else throw new Error();
        } catch (error) {
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };

    getDimensionList = async (data: TRequestPrioritizeData) => {
        try {
            MainStore.changeLoader(true);
            const response = await useGetPrioritizeTableList(data);
            if (response.status === 200 || response.status === 201) {
                this.prioritizeList = response.data;
            } else throw new Error();
        } catch (error) {
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };

    getChartData = async (data: TRequestPrioritizeData) => {
        try {
            MainStore.changeLoader(true);
            const response = await useGetPrioritizeChartData(data);
            if (response.status === 200 || response.status === 201) {
                runInAction(() => {
                    this.chartData = response.data;
                });
            } else throw new Error();
        } catch (error) {
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };
}

const store = new Store();

export default store;
