import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import MainStore from "./MainStore";
import { Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import Loader from "components/Loader/Loader";
import ConfirmDialog from "components/ConfirmDialog";
import AlertDialog from "components/AlertDialog";
import Error403Dialog from "components/errors/Error403";
import Error422Dialog from "components/errors/Error422";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { useLocation } from "react-router-dom";
import { useCognito } from "CognitoProvider";

const PrivateRoute = observer(() => {
  const location = useLocation()
  const cognito = useCognito()

  if (!cognito.getAccessToken()) {
    window.location.href = '/login'
    return
  }

  useEffect(() => {
    StoreLayout.getMyInfo();
    if (!(location.pathname.includes("/select-workspace") || location.pathname === "/")) {
      StorePermission.getFeatures();
      StorePermission.getEntityPermissions();
      StorePermission.getWorkspacePermissions();
    }
  }, []);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === 'userEmail') { // if user changed email or impersonated
        window.location.href = '/'
      }
    };
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [])

  return (
    <>
      <ConfirmDialog />
      <AlertDialog />
      <Error403Dialog />
      <Error422Dialog />

      <Backdrop id={MainStore.loader && "Preloader_1"} sx={{ color: "#fff", zIndex: 1000000 }} open={MainStore.loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        id="Alert_Snackbar"
        anchorOrigin={MainStore.positionSnackbar}
        open={MainStore.openSnackbar}
        onClose={() => MainStore.changeSnackbar(false)}
        autoHideDuration={3000}
        message={MainStore.snackbarMessage}
        key={"bottomleft"}
      >
        <StyledAlert
          icon={MainStore.snackbarSeverity === "error" ? <CancelIconWrapp /> : <CheckIconWrapp />}
          onClose={() => MainStore.changeSnackbar(false)}
          $severity={MainStore.snackbarSeverity}
        >
          {MainStore.snackbarMessage}
        </StyledAlert>
      </Snackbar>
      <Outlet />
    </>
  );
});

const CancelIconWrapp = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2, #bd0202) !important;
`;
const CheckIconWrapp = styled(CheckCircleIcon)`
  color: var(--colorPaletteGreenBackground2, #3e4450) !important;
`;

const StyledAlert = styled(Alert) <{ $severity?: "success" | "info" | "warning" | "error" }>`
  background-color: var(--colorNeutralForeground1, #3e4450) !important;
  color: var(--colorNeutralBackground1, #fff) !important;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: center;
`;

export default PrivateRoute;